import './App.css';
import { Home } from './components/pages/Home';
import { Contact } from './components/pages/Contact';
import React from 'react';

function App() {
  const [currentRoute, setCurrentRoute] = React.useState(window.location.pathname);

  React.useEffect(() => {
    window.onpopstate = () => {
      setCurrentRoute(window.location.pathname);
    };
  }, []);

  return (
    <>
      <div class="w-full h-10rem bg-blue-500 flex justify-center items-center text-white">
        Disclaimer: This is a demo site. It is not functional.
      </div>

      <nav class="w-full flex justify-between items-center gap-4 bg-blue-500/30 p-4">
        <ul class="flex justify-between items-center gap-4">
          <li>
            <a href="/" class="text-white text-3xl hover:text-blue-300">Project Diction</a>
          </li>
          <li>
            <a href="/contact" class="text-white text-3xl hover:text-blue-300">Contact</a>
          </li>
        </ul>
      </nav>

      <main class="w-full" style={{ flex: "1 1 100%" }}>
        {currentRoute === "/" && <Home />}
        {currentRoute === "/contact" && <Contact />}
      </main>
      <footer class="mt-auto text-white text-3xl m-4">
        In development by Sahil Khan
      </footer>
    </>
  );
}

export default App;
