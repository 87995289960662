import React from 'react'

export const Home = () => {
    return (
        <p class="text-white text-3xl">
            Welcome to Project Diction!
            <br />
            Soon you can begin your language journey here!
        </p>
    )
}
