import React from 'react'

export const Contact = () => {
    return (
        <div class="text-white text-3xl">
            Fill out the form below to get in touch with us!
            <form class="flex flex-col gap-4">
                <label for="name" class="text-white">Name:</label>
                <input type="text" id="name" name="name" class="p-2 rounded-md" />
                <label for="email" class="text-white">Email:</label>
                <input type="email" id="email" name="email" class="p-2 rounded-md" />
                <label for="message" class="text-white">Message:</label>
                <textarea id="message" name="message" class="p-2 rounded-md"></textarea>
                <button type="submit" class="bg-blue-500 text-white p-2 rounded-md">Submit</button>
            </form>
        </div>
    );
}
